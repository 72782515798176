import React from 'react'
import styled from '@emotion/styled'

import { Mq } from '../themes/default'
import { Row, Column } from '../atoms/grid'
import { H1 } from '../atoms/heading'
import { Paragraph } from '../atoms/text'
import { LinkButton } from '../atoms/link'
import Layout from '../organisms/layout'
import SEO from '../organisms/seo'

const PageContainer = styled.div`
  padding: 3rem 5%;
  height: 94vh;

  ${Mq.md} {
    padding: 2rem 3%;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 12%;
  }
`

const PageRow = styled(Row)`
  height: 100%;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Page Not Found" />
    <PageContainer>
      <PageRow alignItems="center" flexWrap="wrap">
        <Column width={1}>
          <H1>404 - Page Not Found</H1>
          <LinkButton link={{ id: '', title: 'Go Back Home', path: '/' }} />
        </Column>
      </PageRow>
    </PageContainer>
  </Layout>
)

export default NotFoundPage
